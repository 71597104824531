<template>
  <section class="top-filter">
    <a-form
      ref="formRef"
      class="filter-form"
      layout="inline"
      :model="formState"
    >
      <div class="left">
        <a-form-item label="Cost Ctr" name="costCtr" class="form-item-inline">
          <a-input-search
            v-model:value="formState.costCtr"
            placeholder="请输入"
            enter-button
            @search="doSearch"
            allowClear
            autocomplete="off"
          />
        </a-form-item>

        <a-button type="primary" @click="resetForm"> 重置 </a-button>
      </div>
    </a-form>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  nextTick,
  onActivated,
} from "vue";

export default defineComponent({
  name: "TopFilter",
  components: {},
  emits: ["search", "refresh-table"],
  setup(props, ctx) {
    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      ctx.emit("search", formState);
    };

    const formState = reactive({
      costCtr: "",
    });
    const doSearch = () => {
      ctx.emit("search", formState);
    };
    onMounted(() => {
      nextTick(doSearch);
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  height: 88px;

  .filter-form {
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }

    .form-item-inline {
      min-width: 170px;
      text-align: center;
    }

    .form-item-inline-mid {
      min-width: 190px;
      text-align: center;
    }

    .form-item-inline-long {
      min-width: 320px;
      text-align: center;
    }
  }
}
</style>
