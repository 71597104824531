<template>
  <section class="app-container">
    <Filter @search="handleSearch" @refresh-table="refreshTable"></Filter>
    <List ref="listRef" @edit-click="editClick"></List>
    <edit-reviewer ref="editReviewerRef" @refresh-table="refreshTable" />
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import List from "./components/List";
import EditReviewer from "@/views/home/ConfigManagement/Reviewer/components/EditReviewer";
export default defineComponent({
  name: "maintainReviewer",
  components: {
    EditReviewer,
    List,
    Filter,
  },
  setup() {
    const listRef = ref();
    const editReviewerRef = ref();
    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    const refreshTable = () => {
      listRef.value.doSearch();
    };
    const editClick = (row) => {
      editReviewerRef.value.editClick(row);
    };
    return {
      listRef,
      editReviewerRef,
      handleSearch,
      refreshTable,
      editClick,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
