<template>
  <section class="org-list">
    <a-table
      ref="listRef"
      :columns="columns"
      :data-source="reviewerList"
      rowKey="id"
      :scroll="{
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
      <template #operation="{ record }">
        <a-space>
          <a-button type="primary" ghost @click="editClick(record)">
            <template #icon>
              <EditOutlined />
            </template>
            编辑
          </a-button>
        </a-space>
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";

export default defineComponent({
  name: "ReviewerList",
  components: {},
  emits: ["edit-click"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const listRef = ref();
    const columns = [
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        width: 110,
        ellipsis: true,
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        width: 310,
        ellipsis: true,
      },
      {
        title: "Reviewers",
        dataIndex: "reviewers",
        key: "reviewers",
        width: 310,
        ellipsis: true,
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: 50,
        align: "center",
        slots: {
          customRender: "operation",
        },
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const reviewerList = ref();
    let searchCondition = {};

    const doSearch = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        costCtr: searchCondition.costCtr,
      };
      $api.getReviewerList(params).then((res) => {
        pagination.total = res.data.total;
        reviewerList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const editClick = (record) => {
      ctx.emit("edit-click", record);
    };

    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      listRef,
      columns,
      pagination,
      reviewerList,
      searchClick,
      doSearch,
      editClick,
      tableHeight,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  //height: calc(100vh - 3.90625vw - 4.58333vw - 20px - 80px);
  &:last-child {
    height: calc(100vh - 158px);
  }
  overflow: auto;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
